import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [muted, setMuted] = useState('')
  const [log, setLog] = useState('')
  const [onPhone, setOnPhone] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [currentNumber, setCurrentNumber] = useState('')
  const [isValidNumber, setIsValidNumber] = useState('')
  const [countries] = useState([
    { name: 'United States', cc: '1', code: 'us' },
    { name: 'Great Britain', cc: '44', code: 'gb' },
    { name: 'Colombia', cc: '57', code: 'co' },
    { name: 'Ecuador', cc: '593', code: 'ec' },
    { name: 'Estonia', cc: '372', code: 'ee' },
    { name: 'Germany', cc: '49', code: 'de' },
    { name: 'Hong Kong', cc: '852', code: 'hk' },
    { name: 'Ireland', cc: '353', code: 'ie' },
    { name: 'Singapore', cc: '65', code: 'sg' },
    { name: 'Spain', cc: '34', code: 'es' },
    { name: 'Brazil', cc: '55', code: 'br' },
  ])

  useEffect(() => {
    fetch(`https://2c13-103-179-111-134.ngrok.io/token`)
     .then((response) => console.log(response));
   }, []);
  const onCreateCall = (e) => {
    e.preventDefault()
  }


  return (
    <div className="App">
      <div>
        <label>make a call</label>
        <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </div>
      <div><button onClick={onCreateCall}>Call</button></div> </div>
  );
}

export default App;
